.chart-wrapper {
    position: relative;
    border-bottom: 1px solid #e9ecef;
    // height: 40px;
    width: 100%;
    touch-action: manipulation;

    &:focus,
    &:hover,
    &:active {
        outline: none;
    }

    .chart {
        z-index: 1;
        display: block;
        image-rendering: optimizeSpeed;
        image-rendering: -moz-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: -o-crisp-edges;
        image-rendering: pixelated;
        -ms-interpolation-mode: nearest-neighbor;
    }

    .chart-alert {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
    }

    .graph-settings {
        display: none;
        position: absolute;
        right: 20px;
        width: 24px;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 3;

        &.realtime {
            //left: calc(100% - 23px);
        }
    }

    &:hover {
        .graph-settings {
            display: inline-flex;
        }
    }
}