@import '../../../core/ui/assets/css/variables';


.multiple-select-list {
    height: auto;
    //min-height: 40px;
    border: 1px solid #adb5bd;
    background: #fff;
    border-radius: 6px;

    .MuiSelect-outlined.MuiSelect-outlined {
        padding: 5px 12px;
        min-height: 27px;
        border-radius: 6px;
    }
}

.form-field.success-field {
    .multiple-select-list {
        border-color: #72c26a;
    }
}

.form-field.error-field {
    .multiple-select-list {
        border-color: #ff3b30;
    }
}

div.chip-item-nullable {
    background: none;
    color: #adb5bd;
    letter-spacing: 0.25px;

    .MuiChip-label {
        padding: 0;
    }
}

div.chip-item {
    height: auto;

    .MuiChip-label {
        opacity: 0.5;
        font-family: $font;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #000000;
    }
}

.list-multiple {
    &--wrap {
        box-shadow: none !important;

        &:before {
            content: none !important;
        }

        > .MuiCollapse-container {
            position: absolute;
            top: 40px;
            width: 100%;
            left: 0;
            z-index: 10;
            border-radius: 1px;
            border: solid 1px $text-grey;
            background-color: #ffffff;
            padding: 0 7px;
        }
    }

    &--header {
        &.MuiAccordionSummary-root.Mui-expanded {
            .dropdown-arrow {
                transform: rotate(180deg);
            }
        }

        .MuiAccordionSummary-content.Mui-expanded,
        .MuiAccordionSummary-content {
            margin: 0;
            max-width: 544px;
        }

        &.MuiAccordionSummary-root.Mui-expanded,
        &.MuiAccordionSummary-root {
            min-height: 40px;
            //height: 40px;
            border-radius: 6px;
            border: solid 1px $text-grey;
            padding: 8px 8px 0 8px;

            margin-bottom: 23px;

            .selected-section {
                width: 100%;
                max-width: 500px;


            }

            .selected-item {
                height: 24px;
                padding: 0 13px;
                display: inline-flex;
                align-items: center;
                margin-right: 10px;
                border-radius: 12px;
                margin-bottom: 8px;

                .text {
                    opacity: 0.5;
                    font-family: $font;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.71;
                    letter-spacing: normal;
                    color: #000000;
                }
            }
        }
    }

    &--body {
        &.MuiAccordionDetails-root {
            padding: 2px 0;
            background: #FFFFFF;
        }

        .MuiAccordionSummary-root,
        .MuiAccordionSummary-root.Mui-expanded {
            min-height: 40px;
            height: 40px;
            padding: 0;
        }

        .MuiAccordionSummary-content.Mui-expanded {
            margin: 0;
        }

        .category-name {
            display: flex;
            height: 40px;
            align-items: center;
            width: 100%;
            font-family: $font;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: $text;

            &-box {
                width: 100%;
            }

            .color-circle {
                width: 18px;
                height: 18px;
                border-radius: 100%;
                margin-right: 11px;
            }
        }

        .category-subname {
                display: table-cell;
                vertical-align: middle;

            .color-circle-subname {
                width: 20px;
                height: 20px;
                border-radius: 100%;
                position: absolute;
                left: 3px;
            }
        }

        .MuiAccordionDetails-root {
            padding: 0;
        }

        .selected-item {
            .checked {
                opacity: 0;
            }

            &.active {
                font-weight: bold;

                .checked {
                    opacity: 1;
                }
            }
        }

        ul {
            padding: 0 30px;
            width: 100%;

            .checked {
                margin-right: 8px;
                min-width: 24px;
                min-height: 24px;
            }

            li {
                font-family: $font;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                color: $text;
            }
        }

        .MuiAccordion-root,
        .MuiAccordion-root.Mui-expanded {
            padding: 0 0 0 9px;
            box-shadow: none;
        }

        .MuiAccordion-root:before {
            content: none;
        }
    }
}